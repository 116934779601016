import React from "react"
import { Link } from "gatsby"

import "./footer.css"
/* import "../../styles/global.css" */

function Footer() {
  return (
    <footer className="bg-dark text-light">
      <div className="container p-4 pl-md-0 pr-md-0 d-md-flex justify-content-center">
        <div className="row">
          <div className="col-10 offset-1 col-lg-4 offset-lg-0 mb-3">
            <h5>Über die Kampagne</h5>
            <p className="">
              Wir wollen Schluss machen mit Vorurteilen und zeigen, wie die
              Realität mit Hartz 4 wirklich aussieht. Kein Geld für nix und dann
              auch noch stigmatisiert werden - na super!
            </p>
          </div>

          <div className="col-10 offset-1 col-lg-4 offset-lg-0 mb-3">
            <h5 className="w-100 text-left">Infos</h5>
            <div className="flex-row">
              <ul className="footer-infos pl-0">
                <li>
                  <a
                    className="hover-primary"
                    href="mailto:info@sanktionsfrei.de"
                    target="_blank"
                  >
                    Kontakt
                  </a>
                </li>
                <li>
                  <Link to="/datenschutz" className="hover-primary">
                    Datenschutz
                  </Link>
                </li>
                <li>
                  <Link to="/impressum" className="hover-primary">
                    Impressum
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-10 offset-1 col-lg-4 offset-lg-0 mb-3">
            <h5>Spenden für Sanktionsfrei</h5>
            <span>IBAN: DE53430609671181458700</span>
            <br />
            <span>BIC: GENODEM1GLS</span>
            <br />
            <span>Verwendungszweck: Spende</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
